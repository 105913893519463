import React from 'react';
import ReactDOM from 'react-dom';
import Root from './root.component';
import singleSpaReact from 'single-spa-react';

export const { bootstrap, mount, unmount } = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(err) {
        return err;
    },
    renderType: 'render'
});
